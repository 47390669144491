const cakes = [
  {
    title: "Very Vanilla",
    content: "Our super soft classic vanilla sponge with vanilla bean Swiss meringue buttercream filling. Perfect for those who like it light and elegant."
  },
  {
    title: "Velvet Chocolate",
    content: "Rich chocolate velvet sponge with a creamy ganache made with high quality chocolate. Your choice of white, milk or dark ganache. Perfect for the indulgent chocolate lovers."
  },
  {
    title: "Very Berry Vanilla",
    content: "Our super soft classic vanilla sponge with a mixed berry compote and seasonal fresh berry filling. The perfect choice for those who like their dessert to feel refreshing, fruity, and summery."
  },
  {
    title: "Citrus Queen",
    content: "A zesty lemon velvet cake with a lime curd and orange curd filling. This is a strongly citrusy cake. Perfect for those who love a sour zing."
  },
  {
    title: "OG Red Velvet",
    content: "Authentic red velvet cake with cream cheese filling. No, it’s not just a red colouring that makes this cake special. Ask us about its history. We’d love to share.."
  },
  {
    title: "Spiced Carrot Cake",
    content: "Perfected over a decade, this cake is moist and has a surprising twist. It’s a great balance of sweet and spicy. We’re very proud of this one!"
  },
  {
    title: "Coconut-Mango-Passion",
    content: "Coconut sponge with a mango-passionfruit and toasted coconut filling. Perfect for those who love a tropical vacation."
  }
];

const cupcakes = [
  {
    title: "",
    content: "Starts at $60, sold by the dozen, single flavour only.  You can pick up to 2 colours and we will make a palette for you. All cupcakes are frosted with vanilla bean Swiss meringue buttercream. Choose your own sweetness level for the buttercream from low, medium, high. The starting price is for a simple buttercream rosette. Get in touch for more design options. "
  },
  {
    title: "",
    content: {
      type: "grid",
      key: "cupcakes-content",
      display: {align: "center", my: 0.1, border: "none", boxShadow: "none"},
      value: ["Vanilla", "Vanilla Berry", "Vanilla Caramel", "Chocolate", "Red Velvet", "Lemon", "Spiced Carrot", "Coconut", ""].map(title => ({
        title: title,
        font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
        content: {
          type: "string",
          value: null
        }
      }))
    },
  },
  {
    title: "",
    content: "Customisations will incur additional costs."
  },
];

const macarons = [
  "The perfect personalised gift. Our speciality is having an artist on hand to be able to paint personalised messages or designs making this an elegant present for your loved ones.", 
  "Gallery - Macarons", 
  "Sold as a box of 6 or 12. Artist fee is based on complexity of design. Get in touch for a quote.\nYou can choose to upgrade as many macrons as you like into painted ones.",
  "Box of 6 starts at $21 (simple hand painted designs)."
];

const cheesecakes = [
  {
    title: "",
    content: "Starts at $50."
  },
  {
    title: "",
    content: {
      type: "table",
      key: "cheesecakes-content",
      display: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
      value: [
        {
          name: "Classic",
          _6_inch: "$50",
          _8_inch: "$55",
          _10_inch: "$60"
        },
        {
          title: "Berry Compote ",
          _6_inch: "$50",
          _8_inch: "$55",
          _10_inch: "$60"
        },
        {
          title: "Gulab Jamun",
          _6_inch: "$60",
          _8_inch: "$65",
          _10_inch: "$70"
        }
      ]
    }
  }
];

const cookies = [
  {
    title: "",
    content: "$30, sold by the dozen, single flavour only."
  },
  {
    title: "",
    content: {
      type: "grid",
      key: "cookies-content",
      display: {align: "center", my: 0.1, border: "none", boxShadow: "none"},
      value: ["Chocolate Chip", "Snickerdoodle", "ANZAC", "Chocolate", "Chai Sugar", "Peanut Butter"].map(title => ({
        title: title,
        font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
        content: {
          type: "string",
          value: null
        }
      }))
    },
  },
];

const notes = [
  {
    title: "",
    content: "We have plenty of recipes in our back pocket and had to restrain ourselves in creating this menu. So if you have a particular adventurous flavour you'd like, get in touch and we'll see what we can do."
  },
  {
    title: "",
    content: "All our prices are indicative only. Customizations will incur additional costs. Email us for a personalised quote."
  },
  {
    title: "",
    content: "We do require a 2 week's notice for all cake and cupcake orders. Macarons and cookies can be fit in with lesser notice, based on our calendar."
  },
  {
    title: "Dietary Requirements",
    content: "Get in touch with us for vegan, gluten free, halal, or other special dietary requirements. We are NOT equipped to cater to severe allergies, but can accommodate dietary preferences."
  }
]

export const menu_content = [
  {
		title: "",
    font: {component: "h1", variant:"h1"},
		content: {
			type: "list",
			key: "menu-pages",
      display: {align: "center"},
      value: [
        {
          title: 'Cakes',
          font: {component: "h2", variant: "h2", family: "Kalnia"},
          content: {
            type: "list",
            key: "cakes-content",
            display: {align: "center"},
            value: cakes.map(cake => ({
            title: cake.title,
            font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
            content: {
              type: "string",
              key: cake.title.toLowerCase().replace(' ', '-'),
              display: {align: "left", fontSize: 20},
              value: cake.content
            }
            })).concat({
              title: "Cake Pricing",
              font: {component: "h4", variant: "h4", alignment: "center", family: "Kalnia"},
              content: {
                type: "list",
                key: "cake-pricing-content",
                display: {align: "left", justify: "center", fontSize: 20},
                value: ["Starting prices are for two layers of cake, one layer of filling in between, and a simple buttercream coating in your choice of colour with minimal piping. Choose your own sweetness level for the buttercream from low, medium, high. Extra elements and designs can be added at an additional cost. Check out our Instagram for inspiration - ", "@as.you.whisk", "6 inch round - serves about 15 - starts at $120", "8 inch round - serves about 24 - starts at $180", "10 inch round - serves about 38 - starts at $250"].map((text, index) =>(index === 1 ? {
                  type: "link",
                  key: "contact-instagram",
                  display: { align: "center", justify: "left", display: "flex" },
                  value: {
                    icon: "insta",
                    text: text,
                    href: "https://www.instagram.com/as.you.whisk/",
                    target: "_blank",
                  },
                } : {
                    type: "string",
                    key: `cake-pricing-${index}`,
                    display: {align: "left", fontSize: 20},
                    value: text
                  }
                ))
              }
            })
          }
        },
        {
          title: 'Cupcakes',
          font: {component: "h2", variant: "h2", family: "Kalnia"},
          content: {
            type: "list",
            key: "cupcakes-content",
            display: {align: "center"},
            value: cupcakes.map(cupcake => 
              cupcake.content.hasOwnProperty("value") ?
              (cupcake) : 
              ({
              title: cupcake.title,
              font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
              content: {
                type: "string",
                key: cupcake.title.toLowerCase().replace(' ', '-'),
                display: {align: "left", fontSize: 20},
                value: cupcake.content
              }
            }))
          }
        },
        {
          title: 'Macarons',
          font: {component: "h2", variant: "h2", family: "Kalnia"},
          content: {
            type: "list",
            key: "macarons-content",
            display: {align: "center"},
            value: macarons.map((macaron, index) => (index === 1 ? {
              type: "link",
              key: `macaron-${index}`,
              display: { align: "center", justify: "left", display: "flex", weight: "bold" },
              value: {
                icon: "gallery",
                text: macaron,
                href: "/gallery/#painted-macarons",
              },
            }: {
              type: "string",
              key: `macron-${index}`,
              display: {align: "left", fontSize: 20},
              value: macaron
            }))
          }
        },
        {
          title: 'Burnt Basque Cheesecake',
          font: {component: "h2", variant: "h2", family: "Kalnia"},
          content: {
            type: "list",
            key: "cheesecakes-content",
            display: {align: "center"},
            value: cheesecakes.map(cheesecake => 
              cheesecake.content.hasOwnProperty("value") ?
              (cheesecake) : ({
                title: cheesecake.title,
                font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
                content: {
                  type: "string",
                  key: cheesecake.title.toLowerCase().replace(' ', '-'),
                  display: {align: "left", fontSize: 20},
                  value: cheesecake.content
                }
              }))
          }
        },
        {
          title: 'Cookies',
          font: {component: "h2", variant: "h2", family: "Kalnia"},
          content: {
            type: "list",
            key: "cookies-content",
            display: {align: "center"},
            value: cookies.map(cookie => 
              cookie.content.hasOwnProperty("value") ?
              (cookie) : ({
                title: cookie.title,
                font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
                content: {
                  type: "string",
                  key: cookie.title.toLowerCase().replace(' ', '-'),
                  display: {align: "left", fontSize: 20},
                 value: cookie.content
                }
              }))
          }
        },
        {
          title: "Didn't find what you're looking for?",
          font: {component: "h3", variant: "h3", family: "Kalnia", alignment: "center", size: 30},
          content: {
            type: "list",
            key: "notes-content",
            display: {align: "center"},
            value: notes.map(note => ({
            title: note.title,
            font: {component: "h5", variant: "h5", alignment: "left", weight: "bold"},
            content: {
              type: "string",
              key: note.title.toLowerCase().replace(' ', '-'),
              display: {align: "left", fontSize: 20},
              value: note.content
            }
            }))
          }
        },        
      ]
    }
  }
];