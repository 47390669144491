import {importAll} from '../lib/utilities';
const people = importAll(require.context('../images/people', false, /\.(webp)$/));
export const aboutus_content = [
	{
		title: "",
		content: {
			type: "card",
			key: "letter-card",
			display: {
				flexDirection: "row",
				md: 3,
				style: { border: "none", boxShadow: "none" },
				title: { style: "inherit", font: "inherit" },
			},
			value: {
				src: people['Shyamal And Jc'],
				alt: "Shyamal & Jayashree",
				title: "Meet the people behind it",
				content: {
					type: "string",
					key: "letter",
					display: { align: "justify", boxShadow: 1 },
					value: [
						{
							type: "string",
							key: "letter-hi",
							display: { align: "justify" },
							value: "Hi!",
						},
						{
							type: "string",
							key: "letter-team",
							display: { align: "justify" },
							value: [
								{
									type: "string",
									key: "letter-team-text-1",
									value:
										"We are Shyamal and Jayashree, the two best friends who dreamed up ",
									display: { align: "inherit", display: "inline" },
								},
								{
									type: "string",
									key: "letter-team-brand-1",
									value: "as you whisk",
									display: {
										align: "inherit",
										font: "Kalnia",
										display: "inline",
									},
								},
								{
									type: "string",
									key: "letter-team-text",
									value: " when they were just 15. We met each other in kindergarten and have been inseparable ever since. Back then, Jayashree needed a friend to fend off the bullies and Shyamal needed a friend who'd have the patience to see through the armour. It was the perfect team. Today, it extends to every other aspect of our lives including ",
									display: { align: "inherit", display: "inline" }
								},
								{
									type: "string",
									key: "letter-team-brand-2",
									value: " as you whisk",
									display: {
										align: "inherit",
										font: "Kalnia",
										display: "inline",
									},
								},
								{
									type: "string",
									key: "letter-team-text-stop",
									value: ".",
									display: { align: "inherit", display: "inline" }
								}
							],
						},
						{
							type: "string",
							key: "letter-catch",
							display: { align: "justify" },
							value:
								"We're stoked you're here and would love to chat with you about your next cake. We all need to know there's cake in the future (hopefully in the present too). So, hit us up and let's start designing for your next celebration.",
						},
						{
							type: "string",
							key: "letter-cheers",
							display: { align: "justify" },
							value: "Cheers",
						},
						{
							type: "string",
							key: "letter-signatures",
							display: { align: "justify" },
							value: "Shyamal & Jayashree",
						},
					],
				},
			},
		},
	},
	{
		title: "",
		content: {
			type: "card",
			key: "JC-card",
			display: {
				flexDirection: "row-reverse",
				md: 4,
				style: { border: "none", boxShadow: "none" },
				title: { style: "inherit", font: "inherit" },
			},
			value: {
				src: people['Jc'],
				alt: "Jayashree (Ja-ya-shree)",
				title: "Jayashree (Ja-ya-shree)",
				subtitle: "Our Patisserie Chef",
				content: {
					type: "card",
					key: "story",
					display: {
						flexDirection: "row-reverse",
						md: 3,
						style: { border: "none", boxShadow: "none" },
						title: { style: "inherit", font: "inherit" },
					},
					value: {
						src: people['Jc As A Child'],
						alt: "Young jc with cookies",
						title: "",
						content: {
							type: "string",
							key: "jc-old-picture-text",
							display: { align: "justify" },
							value:
								"Jayashree (Ja-ya-shree) is our patisserie chef trained at William Angliss Institute in Melbourne, Australia. She has been drawn towards baking ever since she figured out that she had the power to turn plain old flour into flower shaped cookies. So when she finished her B.Tech in Biotechnology, she took a year to get trained in all things yummy and beautiful. She then went back to Science to complete her Masters by research in Biotechnology and Applied Biology, and currently works in a lab full-time. Her background in Science aligns with her love for baking and is seen in her persistence to make beautiful cakes that don't compromise on taste.",
						},
					},
				},
			},
		},
	},
	{
		title: "",
		content: {
			type: "card",
			key: "Shyamal-card",
			display: {
				flexDirection: "row",
				md: 4,
				style: { border: "none", boxShadow: "none" },
				title: { style: "inherit", font: "inherit" },
			},
			value: {
				src: people["Shyamal"],
				alt: "Shyamal (Sha-mull)",
				title: "Shyamal (Sha-mull)",
				subtitle: "Our Cake Decorator",
				content: {
					type: "list",
					key: "shyamal-intro",
					value: [
						{
							type: "string",
							key: "shyamal-intro-text",
							display: { align: "justify", display: "flex" },
							value:
								"Shyamal (Sha-mull) is our cake decorator. She is a completely self-taught artist whose favourite medium is oil paint and frosting (don't worry, not together). She is also a published poet and a part-time counsellor with a Bachelor's degree in English Studies and a Master's in Counselling. She loves creating things be it cakes, poetry, paintings, or content for our socials. You can check out her artwork on Instagram handle below. ",
						},
						{
							type: "link",
							key: "shyamal-insta",
							value: {
								icon: "insta",
								text: "@brush.it.off.easely",
								href: "https://www.instagram.com/brush.it.off.easely/",
								target: "_blank",
							},
							display: { align: "center", display: "flex" },
						},
					],
				},
			},
		},
	},
];
