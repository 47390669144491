import Container from "@mui/material/Container";
import Section from "../components/Section";
import { menu_content } from "../content/menu";
import backgroundImage from '../images/background.webp';
import top_background from '../images/top_background.webp';
import bottom_background from '../images/bottom_background.webp';
import CardMedia from "@mui/material/CardMedia";
import SEO from "../components/SEO";
import image from "../images/gallery/painted_macarons/flower_macarons_assorted.webp";
const Menu = () => (<>
	<SEO
		creator="Shyamal & Jayashree"
		type="website"
		url="https://asyouwhisk.com.au/menu"
		image={image}
		title="Menu & Pricing | Cakes, Cupcakes, Macarons, Cheesecakes & Cookies"
		description="Order from our delicious options for your next event. Choose your own sweetness level with your cakes and cupcakes!"		
	/>
	<Container  maxWidth={false} disableGutters  sx={{mt: 8.5,backgroundColor: "#FFFBCA"}}>
	<CardMedia component="img" image={top_background}/>
	<Container maxWidth={false} disableGutters 
	sx={{
		pt: 2,
		backgroundColor: "inherit",
		backgroundImage: `url(${backgroundImage})`, 
		backgroundRepeat: "repeat-y",
		backgroundSize: "contain",
	}}>
		{menu_content.map(Section)}
	</Container>
	<CardMedia component="img" image={bottom_background}/>
	</Container>
</>);

export default Menu;
