import Container from "@mui/material/Container";
import Section from "../components/Section";
import { home_content } from "../content/home";
import { useEffect } from "react";
import SEO from "../components/SEO";
import image from '../images/logo.webp';

const Home = () => {
	useEffect(() => {
		if(window.location.hash){
			window.location.assign(`/${window.location.hash}`);
		}
	});
	return (<>
		<SEO
			creator="Shyamal & Jayashree"
			type="website"
			url="https://asyouwhisk.com.au"
			image={image}
			title="as you whisk&trade; | Custom Painted Buttercream Cakes & Macarons"
			description='Baked Goods that Taste as Good as They Look! as you whisk is a council-registered cake-decorating business catering to South East Melbourne run by the talented artist Shyamal and her certified patisserie chef best friend Jayashree. We make, sell and decorate buttercream cakes, cupcakes, painted macarons, cookies and cheesecakes.'		
		/>
		<Container maxWidth={false} disableGutters sx={{ my: 8.5 }}>
			{home_content.map(Section)}
		</Container>
	</>
	);
};

export default Home;
