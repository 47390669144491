import Home from "./pages/HomePage";
import AboutUs from "./pages/AboutUsPage";
import Gallery from "./pages/GalleryPage";
import Menu from "./pages/MenuPage";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import Header from "./components/Header";
import Footer from "./components/Footer";

let theme = createTheme({
	palette: {
		type: "light",
		primary: {
			main: "rgba(255,255,255,0)",
		},
		secondary: {
			main: "#b59410",
		},
	},
});
theme = responsiveFontSizes(theme);

function App() {
	return (
			<ThemeProvider theme={theme}>
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/contactus" element={<Home />} />
					<Route path="/aboutus" element={<AboutUs/>}/>
					<Route path="/gallery" element={<Gallery/>}/>
					<Route path="/menu" element={<Menu/>}/>
				</Routes>
				<Footer />
			</ThemeProvider>
	);
}

export default App;
