import { Box, Paper } from "@mui/material";
import Copyright from "./CopyrightComponent";

const Footer = () => (
	<Paper
		sx={{
			boxShadow: "0px -15px 2px rgba(50, 50, 50, 0.1)",
			bottom: 0,
			width: "100%",
		}}
	>
		<Box px={{ xs: 1, sm: 3 }} py={{ xs: 1, sm: 2 }}>
			<Copyright />
		</Box>
	</Paper>
);

export default Footer;
