import Container from "@mui/material/Container";
import Section from "../components/Section";
import { aboutus_content } from "../content/aboutus";
import SEO from "../components/SEO";
import image from '../images/people/shyamal_and_jc.webp';
const AboutUs = () => (<>
	<SEO
		creator="Shyamal & Jayashree"
		type="website"
		url="https://asyouwhisk.com.au/aboutus"
		image={image}
		title="Shyamal & Jayashree's Sweet Friendship Story"
		description="About Us - Who is as you whisk? Get to know us by reading our `sweet` story here."		
	/>
	<Container  maxWidth={false} disableGutters sx={{ my: 8.5}}>{aboutus_content.map(Section)}</Container>
</>);

export default AboutUs;