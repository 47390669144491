import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Copyright = () => (
	<Grid
	container
	textAlign={"center"}
	justifyContent="space-evenly"
	sx={{
		display: "flex",
		flexDirection: "row"
	}}
	>
	<Typography variant="body2" color="text.secondary" align="center">
		Copyright
		{" © "}
		{new Date().getFullYear()}
	</Typography>
	<Typography variant="body2" color="text.secondary" align="center">
		<Link color="inherit" href="/">
			as you whisk
		</Link>
		<sup>&trade;</sup>
	</Typography>
	<Typography variant="body2" color="text.secondary" align="center">
		ABN 51 899 212 747
	</Typography>
	</Grid>
);

export default Copyright;
