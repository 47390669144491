import Container from "@mui/material/Container";
import Section from "../components/Section";
import { gallery_content } from "../content/gallery";
import { useEffect } from "react";
import SEO from "../components/SEO";
import image from '../images/gallery/classic_cakes/shyamal_wedding_cake.webp';



const Gallery = () => {
	useEffect(() => {
		if(window.location.hash){
			window.location.assign(`/gallery/${window.location.hash}`);
			window.scrollTo({top: window.scrollY + document.getElementById(window.location.hash.slice(1)).getBoundingClientRect().top - 75});
		}
	});
	window.addEventListener("hashchange", () => {
		if(window.location.hash){
			window.scrollTo({top: window.scrollY + document.getElementById(window.location.hash.slice(1)).getBoundingClientRect().top - 75});
		}
	});
	return (<>
		<SEO
			creator="Shyamal & Jayashree"
			type="website"
			url="https://asyouwhisk.com.au/gallery"
			image={image}
			title="Gallery | Unique Custom & Painted Cake Designs"
			description="Feast your eyes on our previous cake, cupcake, painted macaron, cookie and cheesecake orders"		
		/>
		<Container maxWidth={false} disableGutters sx={{ my: 12.5}}>
			{gallery_content.map(Section)}
		</Container>
	</>);
};

export default Gallery;
